import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {},
  reducers: {
    setSession: (state, action) => ({ ...state, ...action.payload }),
    wipeSession: () => ({}),
  },
});

export const { setSession, wipeSession } = sessionSlice.actions;

export default sessionSlice.reducer;
