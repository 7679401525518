import { createBrowserRouter } from "react-router-dom";
import Home from "../routes/Home";
import Login from "../routes/Login";
import StaffRoot from "../routes/staff/StaffRoot";
import {
  HOME,
  LOGIN,
  STAFF_ROOT,
  MANAGE_USERS,
  CREATE_USER,
  SERVICES,
  GET_QUOTE,
} from "./app-routes";
import UsersTable from "../routes/staff/manage/UsersTable";
import CreateUser from "../routes/staff/manage/CreateUser";
import Services from "../routes/Services";
import ProtectedRoute from "../components/ProtectedRoute";
import Quote from "../routes/Quote";

const router = createBrowserRouter([
  {
    path: HOME,
    children: [
      { index: true, element: <Home /> },
      {
        path: LOGIN,
        element: <Login />,
      },
      {
        path: STAFF_ROOT,
        element: (
          <ProtectedRoute>
            <StaffRoot />
          </ProtectedRoute>
        ),
      },
      {
        path: MANAGE_USERS,
        element: (
          <ProtectedRoute>
            <UsersTable />
          </ProtectedRoute>
        ),
      },
      {
        path: CREATE_USER,
        element: (
          <ProtectedRoute>
            <CreateUser />
          </ProtectedRoute>
        ),
      },
      {
        path: SERVICES,
        element: <Services />,
      },
      {
        path: GET_QUOTE,
        element: <Quote />,
      },
    ],
  },
]);

export default router;
