import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  CUSTOMERS,
  GET_QUOTE,
  HOME,
  LOGIN,
  SERVICES,
  STAFF_ROOT,
} from "../constants/app-routes";
import { useAppSelector } from "../hooks/useRTK";
import { Collapse, IconButton, Navbar } from "@material-tailwind/react";
import { Bars3Icon, PhoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../assets/logo.jpeg";

const Header: React.FC = () => {
  const isAuthed = useAppSelector((state) => state.user.isAuthed);
  const [openNav, setOpenNav] = useState(false);
  const { pathname } = useLocation();

  const NAV_LINKS = [
    { label: "Home", to: HOME },
    { label: "Services", to: SERVICES },
    // { label: "What our customers say", to: CUSTOMERS },
    { label: "Get a quote", to: GET_QUOTE },
    {
      label: isAuthed ? "Staff Area" : "Staff Login",
      to: isAuthed ? STAFF_ROOT : LOGIN,
    },
  ];

  const linkClass = (to: string) =>
    `hover:text-yellow-light hover:font-bold transition-all duration-200 text-white ${
      (to === pathname ||
        (pathname.includes("staff") && to.includes("staff"))) &&
      "text-yellow font-bold"
    }`;

  return (
    <div className="sticky top-0 z-10">
      <Navbar className="sticky max-w-full px-6 bg-opacity-100 border-none rounded-none lg:py-7 bg-dark">
        <div className="flex items-center justify-evenly">
          <img src={Logo} alt="logo" className="max-h-10 lg:max-h-12" />
          <div className="hidden lg:flex lg:space-x-5">
            {NAV_LINKS.map(({ to, label }, index) => (
              <React.Fragment key={to}>
                <Link to={to} className={linkClass(to)} key={to}>
                  {label}
                </Link>
                {index + 1 !== NAV_LINKS.length && (
                  <span key={`dot-${to}`}>·</span>
                )}
              </React.Fragment>
            ))}
          </div>
          <IconButton
            variant="text"
            className="w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="w-6 h-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="w-6 h-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <div className="flex flex-col pt-3 mt-3 space-y-4 text-xl text-right border-t border-t-charcoal border-opacity-20">
            {NAV_LINKS.map(({ to, label }) => (
              <NavLink to={to} className={linkClass(to)} key={to}>
                {label}
              </NavLink>
            ))}
          </div>
        </Collapse>
      </Navbar>
      <div className="flex justify-center py-2 bg-white drop-shadow-lg lg:hidden">
        <a href="tel:+447722065229">
          <div className="flex items-center space-x-2">
            <PhoneIcon fill="#212121" className="w-6" />
            <span>Call us for a quote</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Header;
