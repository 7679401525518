import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/useRTK";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import { MANAGE_USERS } from "../../constants/app-routes";
import { Link } from "react-router-dom";
import { supabase } from "../..";
import { toaster } from "baseui/toast";
import { wipeUser } from "../../userSlice";
import { wipeSession } from "../../sessionSlice";

const StaffZoneCard: React.FC<Record<string, string>> = ({
  to,
  title,
  description,
}) => (
  <Link to={to}>
    <Card className="mb-2 bg-dark text-yellow hover:bg-opacity-90">
      <CardBody>
        <Typography variant="h5" className="text-xl font-bold">
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </CardBody>
    </Card>
  </Link>
);

const StaffRoot = () => {
  const user = useAppSelector((state) => state.user);
  const meta = useAppSelector((state) => state.user.user_metadata);
  const dispatch = useAppDispatch();

  const handleSignout = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      toaster.negative(<span>{error.message}</span>);
    }

    if (!error) {
      dispatch(wipeUser());
      dispatch(wipeSession());
    }
  };

  return (
    <Layout>
      <div className="flex flex-col p-6">
        <Button className="mb-6" onClick={handleSignout}>
          Sign out
        </Button>
        <div className="mb-4 text-2xl font-bold text-black">{`Welcome, ${meta?.name} 👋`}</div>
        <div className="mb-4 border-b border-b-black border-opacity-20" />
        {meta?.admin && (
          <div>
            <div className="pb-4 text-2xl">Admin zone</div>
            <StaffZoneCard
              to={MANAGE_USERS}
              title="Manage Staff"
              description="Create and manage staff access"
            />
            <StaffZoneCard
              to={"/"}
              title="Manage Jobs"
              description="View and manage jobs assigned to staff"
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default StaffRoot;
