import React from "react";
import Layout from "../components/Layout";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { Button } from "baseui/button";
import emailjs from "@emailjs/browser";
import { toaster } from "baseui/toast";

const Quote = () => {
  const { handleSubmit, control } = useForm<Record<string, string>>({
    defaultValues: {
      from_name: "",
      message: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = (values: Record<string, string>) => {
    console.log(values);

    emailjs
      .send("service_at58aap", "template_q2jjyse", values, "n5t9inyz6EuxZ_J_e")
      .then(
        (result) => {
          toaster.positive("Form submitted!");
        },
        (error) => {
          toaster.negative("Something went wrong!");
          console.log(error.text);
        }
      );
  };

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center">
        <div className="p-10 text-center lg:w-1/2">
          <h1 className="mb-4 text-3xl font-bold">Contact us for a quote</h1>
          <p className="mb-8">
            Have a question or a project in mind? We'd love to hear from you!
            Please fill out the form below, and we'll get back to you as soon as
            possible. Your inquiries are important to us, and we're here to
            assist you every step of the way.
          </p>
          <form className="w-full text-left" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="from_name"
              control={control}
              render={({ field }) => (
                <FormControl label={() => "Name"}>
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="John Doe"
                    required
                  />
                </FormControl>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl label={() => "Email address"}>
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="john.doe@email.com "
                    required
                  />
                </FormControl>
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <FormControl label={() => "Phone number"}>
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="07123 456789"
                    required
                  />
                </FormControl>
              )}
            />
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <FormControl label={() => "Message"}>
                  <Textarea
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Hi..."
                    required
                  />
                </FormControl>
              )}
            />
            <Button type="submit">Submit</Button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Quote;
