export const HOME = "/";
export const SERVICES = "/services";
export const CUSTOMERS = "/customer-reviews";
export const GET_QUOTE = "/quote";

export const LOGIN = "/staff-login";
export const STAFF_ROOT = "/staff";

export const MANAGE_USERS = "/staff/manage-users";
export const CREATE_USER = "/staff/manage-users/create";
