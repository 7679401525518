import React from "react";
import { Carousel, Spinner } from "@material-tailwind/react";

import Jetwashing1 from "../assets/jetwashing1.webp";
import Jetwashing2 from "../assets/jetwashing2.webp";

const IMAGES = [
  { url: Jetwashing1, alt: "Driveway clean" },
  { url: Jetwashing2, alt: "Garden patio clean" },
];

const JetwashingCarousel = () => {
  // const [imgsLoaded, setImgsLoaded] = React.useState(false);

  // React.useEffect(() => {
  //   const loadImage = (image: { url: any; alt?: string }) => {
  //     return new Promise((resolve, reject) => {
  //       const loadImg = new Image();
  //       loadImg.src = image.url;
  //       // wait 2 seconds to simulate loading time
  //       loadImg.onload = () =>
  //         setTimeout(() => {
  //           resolve(image.url);
  //         }, 1000);

  //       loadImg.onerror = (err) => reject(err);
  //     });
  //   };

  //   Promise.all(IMAGES.map((image) => loadImage(image)))
  //     .then(() => setImgsLoaded(true))
  //     .catch((err) => console.log("Failed to load images", err));
  // }, []);

  return (
    <div className="flex items-center justify-center mb-8 lg:p-6">
      {/* {imgsLoaded ? ( */}
      <Carousel
        className="rounded-xl"
        loop
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute z-50 flex gap-2 bottom-4 left-2/4 -translate-x-2/4">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
      >
        {IMAGES.map(({ url, alt }) => (
          <img
            key={url}
            src={url}
            alt={alt}
            className="object-cover w-full h-full translate-x-[2px]"
            loading="eager"
          />
        ))}
      </Carousel>
      {/* ) : (
        <Spinner className="w-10 h-10" />
      )} */}
    </div>
  );
};

export default JetwashingCarousel;
