import React from "react";
import Layout from "../../../components/Layout";
import { Controller, useForm } from "react-hook-form";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "baseui/checkbox";
import useCreateUser from "../../../queries/useCreateUser";
import { toaster } from "baseui/toast";
import { MANAGE_USERS } from "../../../constants/app-routes";

interface CreateUserValues {
  name: string;
  email: string;
  password: string;
  admin: boolean;
}

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const { handleSubmit, control, watch } = useForm<CreateUserValues>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      admin: false,
    },
  });

  const { mutate: createUser, isLoading } = useCreateUser({
    onError: (error) => toaster.negative(<span>{error.message}</span>),
    onSuccess: () => {
      toaster.positive("User created");
      navigate(MANAGE_USERS);
    },
  });

  const isSubmittable = Boolean(
    watch("email") && watch("name") && watch("password")
  );

  const onSubmit = async (data: CreateUserValues) => {
    createUser(data);
  };

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center my-20">
        <form
          className="w-[400px] flex flex-col drop-shadow-lg p-10 rounded-2xl sm:border border-ash"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl label="Name">
                <Input
                  name="name"
                  placeholder="Name"
                  onChange={onChange}
                  value={value}
                />
              </FormControl>
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl label="Email">
                <Input
                  name="email"
                  placeholder="Email"
                  onChange={onChange}
                  value={value}
                />
              </FormControl>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl label="Password">
                <Input
                  name="password"
                  placeholder="*****"
                  onChange={onChange}
                  value={value}
                  type="password"
                />
              </FormControl>
            )}
          />
          <Controller
            name="admin"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                name="admin"
                checked={value}
                onChange={onChange}
                overrides={{
                  Root: { style: { marginBottom: "16px" } },
                  Checkmark: {
                    style: {
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                    },
                  },
                }}
              >
                Admin
              </Checkbox>
            )}
          />
          <Button
            isLoading={isLoading}
            disabled={!isSubmittable}
            type="submit"
            className=""
          >
            Create
          </Button>
        </form>
      </div>
    </Layout>
  );
};

export default CreateUser;
