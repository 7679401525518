import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { RouterProvider } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { Provider } from "react-redux";
import store from "./store";
import AuthProvider from "./AuthProvider";
import { createClient } from "@supabase/supabase-js";
import router from "./constants/browser-router";
import { QueryClient, QueryClientProvider } from "react-query";
import { PLACEMENT, ToasterContainer } from "baseui/toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const engine = new Styletron();

export const supabase = createClient(
  // @ts-ignore
  process.env.REACT_APP_SUPABASE_URL,
  // @ts-ignore
  process.env.REACT_APP_PUB_KEY
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <StyletronProvider value={engine}>
          <QueryClientProvider client={queryClient}>
            <ToasterContainer
              placement={PLACEMENT.bottom}
              autoHideDuration={2000}
            >
              <RouterProvider router={router} />
            </ToasterContainer>
          </QueryClientProvider>
        </StyletronProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
