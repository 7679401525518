import React from "react";
import Layout from "../components/Layout";
import Roller from "../assets/roller.webp";
import { GET_QUOTE } from "../constants/app-routes";
import { Link } from "react-router-dom";
import ReviewCarousel, { REVIEWS } from "../components/ReviewCarousel";
import ReviewItem from "../components/ReviewCarousel";

const Home: React.FC = () => {
  return (
    <Layout>
      <div className="overflow-hidden text-dark z-1">
        <div className="flex flex-col items-center px-5 py-10 lg:p-10 ">
          <h1 className="text-2xl font-bold whitespace-nowrap lg:text-4xl">
            Welcome to DC Maintenance
          </h1>
          <div className="mt-7 mb-7 border-b border-dark w-[100px] opacity-30 relative" />
          <div className="text-lg text-center lg:w-1/2">
            <p className="mb-10">
              Your home is a canvas, and we are the master painters who turn
              your vision into a breathtaking masterpiece. Welcome to DC
              Maintenance, your trusted partner in home and commercial
              maintenance services throughout Hampshire and Surrey.
              <br />
              <br />
              We understand that your property is more than just walls; it's a
              reflection of your personality and style. Our commitment to
              excellence has made us the preferred choice for countless home and
              business owners, and we take immense pride in every project we
              deliver.
            </p>
            <h3 className="mb-2 text-xl font-bold lg:text-2xl">
              Why choose us?
            </h3>
            <ul className="mb-2">
              <li className="pb-4">
                <strong>Quality Materials</strong>: We believe in using the
                finest materials to ensure the longevity of our work. From
                premium paints to top-notch tools, we leave no room for
                compromise when it comes to quality.
              </li>
              <li className="pb-4">
                <strong>Reliability</strong>: We value your time and trust. Our
                team is punctual, and we stick to agreed-upon schedules to
                deliver your project on time, without sacrificing quality.
              </li>
              <li className="pb-4">
                <strong>Competitive Pricing</strong>: Exceptional craftsmanship
                shouldn't break the bank. We offer competitive pricing without
                compromising on the quality of our work, making us the
                budget-friendly choice for homeowners in Hampshire and Surrey.
              </li>
            </ul>
            <div className="py-6 border-y border-y-dark border-opacity-40">
              <ReviewItem review={REVIEWS[0]} />
            </div>
            <div className="p-4 mb-8 rounded-lg ">
              <h4 className="mb-2 text-xl font-bold lg:text-2xl">
                Our Services
              </h4>
              <p className="mb-5">
                Whether you're looking to refresh the interior of your home, add
                a touch of elegance to your office, revitalize your outdoor
                space, or embark on any other project within our extensive
                portfolio, we've got you covered. Our wide range of services
                includes:
              </p>
              <ul className="flex flex-col items-center px-4 lg:px-28">
                <li>
                  <span className="font-bold text-yellow">Painting: </span>From
                  selecting the perfect color palette to delivering flawless
                  finishes, our painting experts breathe new life into your
                  spaces.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />
                <li>
                  <span className="font-bold text-yellow">Decorating: </span>We
                  turn your design dreams into reality, adding style and
                  sophistication to your interiors.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />

                <li>
                  <span className="font-bold text-yellow">Landscaping: </span>
                  Our landscaping team transforms your outdoor space into a
                  sanctuary, blending creativity with nature.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />
                <li>
                  <span className="font-bold text-yellow">Jet Washing: </span>We
                  remove dirt, grime, and years of wear and tear, revealing the
                  hidden beauty of your surfaces.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />
                <li>
                  <span className="font-bold text-yellow">Lighting: </span>
                  Illuminate your surroundings with our customized lighting
                  solutions, creating ambiance and enhancing security.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />
                <li>
                  <span className="font-bold text-yellow">Fencing: </span>
                  Secure your property while enhancing its aesthetic appeal with
                  our top-quality fencing services.
                </li>
                <div className="w-4 py-2 mb-2 border-b-2 border-b-yellow opacity-60" />
                <li>
                  <span className="font-bold text-yellow">Carpentry: </span>
                  From custom cabinets to unique furniture pieces, our
                  carpenters craft functional works of art.
                </li>
              </ul>
            </div>
            <div className="py-6 mb-6 border-y border-y-dark border-opacity-40">
              <ReviewItem review={REVIEWS[1]} />
            </div>
            <p>
              DC Maintenance is more than just a painting and decorating
              company; we are your partners in turning your home into a
              masterpiece. Explore our portfolio, read our client testimonials,
              and get in touch for a free consultation. Let's bring your vision
              to life, one brushstroke at a time.
              <br />
              <br />
              <Link
                to={GET_QUOTE}
                className="font-bold underline hover:text-yellow"
              >
                Contact us
              </Link>{" "}
              today to schedule your consultation and discover how we can
              transform your space into a masterpiece.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
