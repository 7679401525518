import React, { useState } from "react";
import Layout from "../../../components/Layout";
import useUsers from "../../../queries/useUsers";
import { Button, Spinner } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { CREATE_USER } from "../../../constants/app-routes";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import DeleteUserModal from "../../../components/DeleteUserModal";

const TABLE_HEAD = ["Name", "Email", "Admin"];

const UsersTable: React.FC = () => {
  const { data, isLoading, isSuccess } = useUsers();
  console.log(isLoading, isSuccess);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Layout>
      {isLoading && (
        <div className="grid mt-16 place-content-center ">
          <Spinner color="green" className="w-20 h-20" />
        </div>
      )}
      {data?.length > -1 && isSuccess && (
        <div className="container flex flex-col px-6 mx-auto mt-6 mb-10 space-y-2">
          <div className="flex items-start justify-between">
            <span className="pt-2 text-3xl">Staff</span>
            <Link to={CREATE_USER} className="self-end mb-4">
              <Button size="lg" className="bg-yellow text-dark">
                Create
              </Button>
            </Link>
          </div>
          <table className="p-4 overflow-scroll text-left bg-white rounded">
            {TABLE_HEAD.map((head) => (
              <th className="w-min">
                <span>{head}</span>
              </th>
            ))}
            <tbody>
              {data.map((user: any, index: number) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-2"
                  : "p-2 border-b border-blue-gray-50";

                return (
                  <tr>
                    <td className={classes}>
                      <span>{user?.user_metadata?.name}</span>
                    </td>
                    <td className={classes}>
                      <span>{user?.email}</span>
                    </td>
                    <td className={classes}>
                      <span>{!user?.user_metadata?.admin ? "❌" : "✅"}</span>
                    </td>
                    <td className={classes}>
                      <TrashIcon
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => {
                          setSelectedUser(user?.user_metadata?.name);
                          setSelectedUserId(user.id);
                          setDeleteModalOpen(true);
                        }}
                      />
                    </td>
                    <td className={classes}>
                      <PencilSquareIcon className="w-5 h-5" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <DeleteUserModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        userName={selectedUser}
        id={selectedUserId}
      />
    </Layout>
  );
};

export default UsersTable;
