import { useQuery } from "react-query";
import { BE_ENDPOINT } from "../constants/api";

const useUsers = () =>
  useQuery(["user-list"], async () => {
    const response = await fetch(`${BE_ENDPOINT}/list-users`);

    return response.json();
  });

export default useUsers;
