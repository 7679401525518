import { Carousel } from "@material-tailwind/react";
import React from "react";

const ReviewItem = ({ review }: { review: string }) => (
  <div className="px-2 py-4 text-white bg-black border-gray-300 border-y rounded-xl lg:py-10 lg:px-16 border-opacity-40">
    <span className="text-lg">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</span>
    <div className="py-1 border-b border-b-black border-opacity-40" />
    <span className="text-xs lg:text-sm">{review}</span>
  </div>
);

export const REVIEWS = [
  "Daniel is an incredibly skilled and hard-working young decorator. Don't let his years fool you. He's been working around pros since he was 12. He knows how to prep, make right, paint and wallpaper like a true pro. He did an excellent job with a full refurb of my 3 bed period house (painting between beams, wallpapering, varnishing and hanging blinds) - even overcoming some shoddy plasterwork which he painstakingly repaired himself. I can't recommend him highly enough.",
  "The work carried out by Daniel was excellent. showed him the picture of the wall design and he did same design as expected. Was very professional and polite. He cleaned and tidied everything when he finished for the day. Would definitely recommend him and will use his expertise again in the future. Thank you Daniel for a great job done.",
  "Daniel and his team were fantastic, we had an urgent deadline and they achieved it by working very hard and very long hours! one shift was a 12 hour marathon, which you very rarely see these days. The quality of the work was excellent and it was very pleasant to work with people who enjoy what they do. Great Job and have recommended to at least 3 people. Thank you Daniel",
  "As always Daniel has been professional and reliable after finding him on my builder.com. Initially wanted to test the market with reliability of painters and decorators and therefore put a job on for a resand and paint. He responded and it was done and finished that day. That gave me the confidence to engage him for a whole redecoration throughout my 3 bedroom house in 7 days. I can't recommend him enough",
];

export default ReviewItem;
