import { KIND } from "baseui/button";
import { Modal, ModalBody, ModalButton, ModalFooter } from "baseui/modal";
import React, { Dispatch, SetStateAction } from "react";
import useDeleteUser from "../queries/useDeleteUser";
import { toaster } from "baseui/toast";
import { useQueryClient } from "react-query";

interface DeleteUserModalProps {
  id: string;
  userName: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  id,
  userName,
  isOpen,
  setIsOpen,
}) => {
  const queryClient = useQueryClient();

  const { mutate: deleteUser } = useDeleteUser({
    onError: () => toaster.negative("There was an issue deleting this person"),
    onSuccess: () => {
      queryClient.invalidateQueries(["user-list"]);
      toaster.positive("User deleted");
    },
  });

  const handleDelete = () => {
    deleteUser({ id });
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeable
      autoFocus
      animate
    >
      <ModalBody className="text-lg">
        <span className="text-lg text-dark">{`Are you sure you want to delete '${userName}'`}</span>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.tertiary}>Cancel</ModalButton>
        <ModalButton onClick={handleDelete}>Delete</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteUserModal;
