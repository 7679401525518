import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";
import Instagram from "./Instagram";
import Logo from "../assets/logo.jpeg";

const Footer: React.FC = () => {
  return (
    <footer className="flex items-center justify-center text-white bg-dark">
      <div className="w-full px-4 py-10 lg:w-1/2">
        <img
          alt="logo"
          src={Logo}
          className="max-w-[100px] md:max-w-[140px] lg:max-w-[160px] pb-4"
        />
        <p className="mb-2 text-2xl font-bold underline">Get in touch</p>
        <a href="tel:+447722065229">
          <div className="flex mb-2 space-x-2">
            <PhoneIcon className="w-6" />
            <span className="hover:underline">07722 065229</span>
          </div>
        </a>
        <a
          href="mailto:info@dcmaintenanceltd.co.uk"
          className="flex mb-2 space-x-2"
        >
          <EnvelopeIcon className="w-6" />
          <span className="hover:underline">info@dcmaintenanceltd.co.uk</span>
        </a>
        <a
          href="https://www.instagram.com/dc_maintenanceltd/"
          className="flex space-x-2"
          target="_blank"
          rel="noreferrer"
        >
          <div className="w-6">
            <Instagram />
          </div>
          <span className="hover:underline">@dc_maintenanceltd</span>
        </a>
        <div className="py-1 my-4 border-b border-white opacity-40" />
        <span>Copyright © 2023 DC Maintenance</span>
      </div>
    </footer>
  );
};

export default Footer;
