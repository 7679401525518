import { useMutation } from "react-query";
import { BE_ENDPOINT } from "../constants/api";

interface CreateUserValues {
  name: string;
  email: string;
  password: string;
  admin: boolean;
}

const useCreateUser = ({
  onError,
  onSuccess,
}: {
  onError: (error: Error) => void;
  onSuccess: () => void;
}) =>
  useMutation(
    async (payload: CreateUserValues) => {
      const response = await fetch(`${BE_ENDPOINT}/create-user`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: new Headers({ "content-type": "application/json" }),
      });

      const data = await response.json();

      if (data.status && data.status !== 200) {
        throw new Error(data?.message);
      }

      return data;
    },
    {
      onSuccess,
      onError: (error: Error) => onError(error),
    }
  );

export default useCreateUser;
