import React from "react";
import { useAppDispatch } from "./hooks/useRTK";
import { setUser, wipeUser } from "./userSlice";
import { supabase } from ".";
import { setSession, wipeSession } from "./sessionSlice";

interface AuthProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error) {
        dispatch(wipeUser());
        dispatch(wipeSession());
      }

      if (data.session) {
        dispatch(setSession(data.session));
        dispatch(setUser({ isAuthed: true, ...data.session.user }));
      }
    };

    fetchSession();
  }, [dispatch]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProvider;
