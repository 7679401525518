import React from "react";

// Import the functions you need from the SDKs you need
import Layout from "../components/Layout";
import { Controller, useForm } from "react-hook-form";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { Button } from "baseui/button";
import { setUser } from "../userSlice";
import { useNavigate } from "react-router-dom";
import { STAFF_ROOT } from "../constants/app-routes";
import { useAppDispatch, useAppSelector } from "../hooks/useRTK";
import { supabase } from "..";
import { setSession } from "../sessionSlice";
import { toaster } from "baseui/toast";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const isAuthed = useAppSelector((state) => state.user.isAuthed);
  const [error, setError] = React.useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthed) {
      navigate(STAFF_ROOT);
    }
  }, [isAuthed, navigate]);

  const { handleSubmit, control, watch } = useForm<LoginFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LoginFormValues) => {
    const { email, password } = data;

    const { data: authData, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (authData.user) {
      dispatch(
        setUser({
          isAuthed: true,
          ...authData.user,
        })
      );
    }

    if (authData.session) {
      dispatch(setSession(authData.session));
    }

    if (error?.message) {
      toaster.negative(error.message);
    }
  };

  const isSubmittable = !Boolean(watch("email") && watch("password"));

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center mt-20">
        <h1 className="text-3xl font-bold">Login</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[400px] flex flex-col bg-ash p-10 rounded-2xl mb-20"
        >
          <FormControl label="Email">
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  placeholder="something@gmail.com"
                />
              )}
            />
          </FormControl>
          <FormControl label="Password">
            <Controller
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="password"
                  placeholder="***********"
                />
              )}
            />
          </FormControl>
          <Button
            disabled={isSubmittable}
            className="align-self-center"
            type="submit"
          >
            Login
          </Button>
        </form>
      </div>
    </Layout>
  );
};

export default Login;
