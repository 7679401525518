import { createSlice } from "@reduxjs/toolkit";

export interface UserSliceContent {
  isAuthed: boolean;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthed: false,
  } as any,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
    wipeUser: () => ({ isAuthed: false }),
  },
});

export const { setUser, wipeUser } = userSlice.actions;

export default userSlice.reducer;
