import React from "react";
import Layout from "../components/Layout";
import PaintingCarousel from "../components/PaintingCarousel";
import JetwashingCarousel from "../components/JetwashingCarousel";
import LandscapingCarousel from "../components/LandscapingCarousel";
import GardeningCarousel from "../components/GardeningCarousel";

const Landscaping = () => {
  return (
    <Layout>
      <div className="overflow-hidden text-dark z-1">
        <div className="flex flex-col items-center px-4 py-10 lg:p-10 ">
          <h1 className="mb-10 text-5xl font-bold">Services</h1>
          <div className="text-lg text-center lg:w-1/2">
            <p className="mb-10">
              At DC Maintenance, we take pride in providing a wide range of
              top-notch services to transform your space into a haven of beauty
              and functionality. With years of experience and a commitment to
              excellence, we are your trusted partner for all your home
              improvement needs. Explore our services below:
            </p>
            <h3 className="mb-2 text-xl font-bold lg:text-2xl">
              Painting & Wallpapering
            </h3>
            <p className="mb-4">
              Our professional decorators are skilled in transforming your home
              or business with a fresh coat of paint or wallpaper. Whether
              you're looking to refresh your interior walls or give your
              property's exterior a facelift, our painting and wallpapering
              services are tailored to meet your specific needs. We offer:
            </p>
            <ul className="mb-2">
              <li className="pb-4">
                <strong>Interior Painting</strong>: Let us breathe new life into
                your living spaces with a variety of color options and finishes.
              </li>
              <li className="pb-4">
                <strong>Wallpaper installation</strong>: Choose from a wide
                range of contemporary and timeless designs.
              </li>
              <li className="pb-4">
                <strong>Exterior Painting</strong>: Protect and beautify your
                property with durable, weather-resistant paint.
              </li>
              <li className="pb-4">
                <strong>Wallpaper Removal</strong>: Safely remove old or damaged
                wallpaper to prepare your walls for a fresh start.
              </li>
              <li className="pb-4">
                <strong>Commercial Painting</strong>: Enhance the aesthetics and
                professionalism of your business premises.
              </li>
            </ul>
            <PaintingCarousel />

            {/* Landscaping */}
            <h3 className="mb-2 text-xl font-bold lg:text-2xl">Landscaping</h3>
            <p className="mb-4">
              Create an outdoor oasis that reflects your style and enhances your
              property's curb appeal with our landscaping services. Our
              experienced landscapers can turn your outdoor dreams into reality.
              We offer:
            </p>
            <ul className="mb-2">
              <li className="pb-4">
                <strong>Landscape Design</strong>: Collaborate with our head
                landscaper Kay to create a customized design that meets your
                aesthetic and functional needs.
              </li>
              <li className="">
                <strong>Planting and Hardscaping</strong>: From lush gardens to
                stone pathways, we bring your landscaping vision to life.
              </li>
              <li className="pb-4">
                <strong>Fencing</strong>: Ensure privacy, protect your garden
                from wildlife, and add a touch of charm with our fencing options
              </li>
            </ul>
            <LandscapingCarousel />
            {/* Gardening */}
            <h3 className="mb-2 text-xl font-bold lg:text-2xl">Gardening</h3>
            <p className="mb-4">
              Elevate your outdoor oasis with our comprehensive gardening
              services. We're passionate about transforming your green spaces
              into vibrant, flourishing landscapes. Our expert team offers a
              wide range of gardening solutions to suit your needs:
            </p>
            <ul className="mb-2">
              <li className="pb-4">
                <strong>Planting Services</strong>: From colorful flower beds to
                lush greenery, we specialize in planting and maintaining a
                variety of plants, shrubs, and trees.
              </li>
              <li className="pb-4">
                <strong>Lawn Care</strong>: Achieve the lush, green lawn you've
                always wanted with our lawn care expertise, including mowing,
                fertilization, and weed control.
              </li>
              <li className="pb-4">
                <strong>Irrigation Systems</strong>: Keep your garden thriving
                with efficient irrigation solutions that conserve water and
                nourish your plants.
              </li>
            </ul>
            <GardeningCarousel />
            {/* Jet Washing */}
            <h3 className="mb-2 text-xl font-bold lg:text-2xl">Jet Washing</h3>
            <p className="mb-4">
              Restore the beauty of your outdoor surfaces with our high-pressure
              jet washing services. Our state-of-the-art equipment removes dirt,
              grime, and stains from a variety of surfaces, including:
            </p>
            <ul className="mb-2">
              <li className="pb-4">
                <strong>Driveways and Walkways</strong>: Make your pathways safe
                and inviting.
              </li>
              <li className="pb-4">
                <strong>Patios and Decks</strong>: Extend the life of your
                outdoor entertainment areas.
              </li>
              <li className="pb-4">
                <strong>Exterior Walls and Siding</strong>: Enhance the
                appearance and longevity of your property's exterior.
              </li>
            </ul>
            <JetwashingCarousel />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Landscaping;
