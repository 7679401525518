import React from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../constants/app-routes";
import { useAppSelector } from "../hooks/useRTK";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  if (!user.isAuthed) navigate(LOGIN, { replace: true });

  return <>{children}</>;
};

export default ProtectedRoute;
